import axios from 'axios';

export default class SurveyService {
  static answerSurvey(survey) {
    return axios.post("/survey-api/survey", survey);
  }

  static answerSurveySingleDelivery(surveySingleDelivery) {
    return axios.post("/survey-api/survey/single-delivery", surveySingleDelivery);
  }

  static putObservation(data) {
    return axios.patch('/survey-api/survey/observation', data);
  }

  static getLinkSurvey(id) {
    return axios.get(`/survey-api/survey/link-survey/${id}`);
  }
}
