export default class Survey {
  constructor(obj = {}) {
    this.deliverableIds = obj.deliverableIds || [];
    this.clientName = obj.clientName || null;
    this.clientEmail = obj.clientEmail || null;
    this.grade = obj.grade || null;
    this.observation = obj.observation || null;
    
  }
}
